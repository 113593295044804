<template>
  <div>

    <b-row class="kb-search-content-info match-height">
      <b-col cols="12">
        <b-card
          no-body
          class="pdf-preview-card"
        >
          <b-card-body>
            <div
              v-for="item in filteredKB"
              :key="item.id"
              style="margin-bottom: 8px"
            >
              <a
                :href="item.src"
                target="_blank"
              >
                <feather-icon
                  icon="YoutubeIcon"
                  size="20"
                />
                {{ item.title }}
              </a>
              <br>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCardBody, BCol,
  BRow,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCol,
    BCard,
    BCardBody,
    BRow,
    FeatherIcon,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],
      data: [
        {
          title: 'How to set up your TrafficManager platform',
          description: 'Step by step guide on how to configure the TrafficManager tracking platform as a white-label on your domain.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=mrRfiAPNBxI&ab_channel=TrafficManager',
          type_platform: 'clients',
        },
        {
          title: 'Important platform initial settings',
          description: 'Important platform initial settings',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=_ptZzkBnrAU&feature=youtu.be',
          type_platform: 'sass',
        },
        {
          title: 'How to create your first offer/campaign',
          description: 'How to create your first offer/campaign',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=V__x8Rd3FPc',
          type_platform: 'sass',
        },
        {
          title: 'Where can I find my platform Server to Server (S2S) Posback details',
          description: 'Where can I find my platform Server to Server (S2S) Posback details',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=pktM88Z9ZDw',
          type_platform: 'sass',
        },
        {
          title: 'How to manually create new users on your platform',
          description: 'How to manually create new users on your platform',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=rnjOw7sof3Q',
          type_platform: 'sass',
        },
        {
          title: 'How to modify a user password from the admin dashboard',
          description: 'How to modify a user password from the admin dashboard',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=mrywhemHOTU',
          type_platform: 'sass',
        },
        {
          title: 'How to add a custom deal or custom payout for an affiliate',
          description: 'How to add a custom deal or custom payout for an affiliate',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=MthVqSg7BWg',
          type_platform: 'sass',
        },
        {
          title: 'Create and manage an affiliate manager account',
          description: 'Create and manage an affiliate manager account',
          width: 550,
          height: 315,
          // src: 'https://www.youtube.com/watch?v=9nYw5edecME&feature=youtu.be',
          src: 'https://www.youtube.com/watch?v=kEwoKpK7lzE',
          type_platform: 'sass',
        },
        {
          title: 'Custom Affiliate Pre-Agreements Setup & Digital Signature - setup guide',
          description: 'TrafficManager Custom Affiliate Pre-Agreements Setup & Digital Signature - setup guide',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=NMBu3xDmEck&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: "How to Customize Your Platform's Login Registration Page - TrafficManager",
          description: "How to Customize Your Platform's Login Registration Page - TrafficManager",
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=0khYIJ6hJ9c&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'S2S Postback Integration for Beginners - A Step-by-Step Video Guide by TrafficManager com',
          description: 'S2S Postback Integration for Beginners - A Step-by-Step Video Guide by TrafficManager com',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=jewRa7DyOUI&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to add a custom private domain in your TrafficManager Platform (affiliate side)',
          description: 'How to add a custom private domain in your TrafficManager Platform (affiliate side)',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=sRVp_a-Rn0o&list=PLD7Gac3WYXDKp6nUCKLRIVlAAgLpzdQlu&index=12&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to add a custom public domain in your TrafficManager Platform - (admin side)',
          description: 'How to add a custom public domain in your TrafficManager Platform - (admin side)',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=hSeL5B61bZ8&list=PLD7Gac3WYXDKp6nUCKLRIVlAAgLpzdQlu&index=13&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to create custom affiliate ShortLinks - TrafficManager.com',
          description: 'This innovative tool allows affiliates to create user-friendly tracking URLs for promoting your affiliate program\'s products or services, moving from complex tracking parameters to simple and easy-to-remember URLs enabling seamless and easier tracking. From now on, tracking your sales will be possible even across the most difficult mediums like influencers-generated content, live streams, TV or radio shows, commercials, videos, music, movies, and so on.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=qesUiUuXTv8&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to delete player deposits in TrafficManager',
          description: 'How to delete player deposits in TrafficManager',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=qg68cnhzEEk',
          type_platform: 'sass',
        },
        {
          title: 'Adding Multiple Casino Brands in the TrafficManager Affiliate Tracking Platform',
          description: 'Adding Multiple Casino Brands in the TrafficManager Affiliate Tracking Platform',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=XaMECzCsLJY',
          type_platform: 'sass',
        },
      ],
    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.data.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.description.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

iframe {
  width: 100%;
  max-width: 550px;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .mobile-UX{
    width: 100% !important;
  }
  .kb-search-content, .text-center{
    height: fit-content;
    padding:15px;
  }

  .text-center p{
    text-align: justify !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {

}

/* smaller phone */
@media only screen and (max-width: 365px)  {

}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
